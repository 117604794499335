import { LinearProgress } from '@material-ui/core';
import { navigate } from 'components/Link';
import DoughnutChart from 'controlTower/chartUtils/DoughnutChart';
import { greenDarkPh, redDarkPh } from 'controlTower/chartUtils/colors';
import React from 'react';
import { useQuery } from 'react-query';
import BottomNavSales from 'sales/BottomNavSales';
import { useGState } from 'state/store';
import { StringParam, useQueryParam } from 'use-query-params';
import { useBranchId, useCurrencyFormatter, useDate } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { format0 } from 'utils/utils';
import TopNav from './TopNav';

export default function SalesPerformance() {
  const [tab = 'daily', setTab] = useQueryParam('tab', StringParam);
  const date = useDate();
  const nodeId = useBranchId();

  const { data: SalesData, status } = useQuery(
    date && nodeId && ['salesPerformance_stl', { date, nodeId, tab }],
    () =>
      fetch(
        `/client/metrics/salesPerformance?date=${date}&id=${nodeId}${
          tab === 'MTD' ? '&mtd=true' : ''
        }`
      )
  );

  if (SalesData) {
    SalesData.sales = SalesData.sales + SalesData.sales * ((SalesData.taxRate ?? 0) / 100);
    SalesData.ptmAverage =
      SalesData.ptmAverage + SalesData.ptmAverage * ((SalesData.taxRate ?? 0) / 100);
  }

  const { data: RSPData, status: RSPDataLoading } = useQuery(
    date && nodeId && ['salesPerformance_stl_RSP', { date, nodeId, tab }],
    () =>
      fetch(
        `/client/metrics/rsp/salesPerformance?date=${date}&id=${nodeId}${
          tab === 'MTD' ? '&mtd=true' : ''
        }`
      )
  );

  const formatCurrency = useCurrencyFormatter();
  const permissions = useGState((s) => s.permission)?.map((p) => p.code);
  const daburPermission = permissions.includes('gtm.salesTeamDashboard.dailySalesTarget');

  const data = {
    visited: SalesData?.visited + (RSPData?.visited || 0),
    passedBy: SalesData?.passedBy + (RSPData?.passedBy || 0),
    total: SalesData?.total + (RSPData?.total || 0),
    reportedCount: SalesData?.reportedCount + (RSPData?.reportedCount || 0),
    salesmanCount: SalesData?.salesmanCount + (RSPData?.salesmanCount || 0),
    productiveCount: SalesData?.productiveCount + (RSPData?.productiveCount || 0),
    activeRetailersCount: SalesData?.activeRetailersCount + (RSPData?.activeRetailersCount || 0),
    allBeatRetailersCount: SalesData?.allBeatRetailersCount + (RSPData?.allBeatRetailersCount || 0),
    sales: SalesData?.sales + (RSPData?.sales || 0),
    ptmAverage: SalesData?.ptmAverage + (RSPData?.ptmAverage || 0),
    beatDaysCount: SalesData?.beatDaysCount + (RSPData?.beatDaysCount || 0),
    monthlySalesTarget: SalesData?.monthlySalesTarget + (RSPData?.monthlySalesTarget || 0),
    activeBeatRetailersCount:
      SalesData?.activeBeatRetailersCount + (RSPData?.activeBeatRetailersCount || 0),
    beatRetailersCount: SalesData?.beatRetailersCount + (RSPData?.beatRetailersCount || 0),
    igpTarget: SalesData?.igpTarget + (RSPData?.igpTarget || 0),
    igpAchieved: SalesData?.igpAchieved + (RSPData?.igpAchieved || 0),
    dgpTarget: SalesData?.dgpTarget + (RSPData?.dgpTarget || 0),
    dgpAchieved: SalesData?.dgpAchieved + (RSPData?.dgpAchieved || 0),
    prevMonthDgpAchieved: SalesData?.prevMonthDgpAchieved + (RSPData?.prevMonthDgpAchieved || 0),
    prevMonthDgpTarget: SalesData?.prevMonthDgpTarget + (RSPData?.prevMonthDgpTarget || 0),
    linesCut: SalesData?.linesCut + (RSPData?.linesCut || 0),
    linesTarget: SalesData?.linesTarget + (RSPData?.linesTarget || 0),
    mtdActiveRetailersCount:
      SalesData?.mtdActiveRetailersCount + (RSPData?.mtdActiveRetailersCount || 0),
    dailySalesTarget: (SalesData?.dailySalesTarget || 0) + (RSPData?.dailySalesTarget || 0),
    achievedBrands: (SalesData?.achievedBrands || 0) + (RSPData?.achievedBrands || 0),
    totalBrands: (SalesData?.totalBrands || 0) + (RSPData?.totalBrands || 0),
    achievedOutlets: (SalesData?.achievedOutlets || 0) + (RSPData?.achievedOutlets || 0),
    totalOutlets: (SalesData?.totalOutlets || 0) + (RSPData?.totalOutlets || 0),
  };
  const {
    reportedCount,
    beatDaysCount,
    beatRetailersCount,
    salesmanCount,
    visited,
    passedBy,
    total,
    linesCut,
    linesTarget,
    productiveCount,
    mtdActiveRetailersCount,
    prevMonthDgpAchieved,
    prevMonthDgpTarget,
    dgpAchieved,
    dgpTarget,
    igpAchieved,
    igpTarget,
    sales,
    ptmAverage,
    monthlySalesTarget,
    allBeatRetailersCount,
    activeBeatRetailersCount,
    activeRetailersCount,
    dailySalesTarget,
    achievedOutlets,
    totalOutlets,
    achievedBrands,
    totalBrands,
  } = data || {};
  const doughnuts = [
    {
      label: 'Sales rep present',
      link: 'salesRep',
      permission: 'gtm.salesTeamDashboard.salesRepPresent',
      value: reportedCount,
      total: tab === 'MTD' ? beatDaysCount : salesmanCount,
      textContent:
        reportedCount && (tab === 'daily' ? beatDaysCount : salesmanCount) > 0
          ? `${format0((100 * reportedCount) / tab === 'MTD' ? beatDaysCount : salesmanCount)}%`
          : '',
      cutOffs: [1, 1],
      pending: {
        MTD: { label: `${beatDaysCount - reportedCount}  man days absent` },
        daily: { label: `${salesmanCount - reportedCount} absent` },
      },
    },
    {
      label: 'Call compliance',
      link: 'callCompliance',
      permission: 'gtm.salesTeamDashboard.callCompliance',
      value: visited + passedBy,
      total,
      textContent: `${format0(((visited + passedBy) * 100) / total)}%`,
      cutOffs: [0.8, 0.7],
      pending: {
        MTD: { label: `${total - (visited + passedBy)}  non-compliant` },
        daily: { label: `${total - (visited + passedBy)} non-compliant` },
      },
    },
    {
      label: 'Edge Score',
      link: 'edgeScore',
      permission: 'gtm.salesTeamDashboard.edgeScore',
      value: linesCut,
      total: linesTarget,
      textContent: `${
        isNaN(linesCut / linesTarget) ? '--' : format0((linesCut * 100) / linesTarget)
      }%`,
      cutOffs: [1, 0.9],
      pending: {
        MTD: {
          label: `${isNaN(linesTarget - linesCut) ? '--' : linesTarget - linesCut}  not achieved`,
        },
        daily: {
          label: `${isNaN(linesTarget - linesCut) ? '--' : linesTarget - linesCut}  not achieved`,
        },
      },
    },
    {
      label: 'On-Route Productivity',
      link: 'callProductivity',
      permission: 'gtm.salesTeamDashboard.callProductivity',
      value: productiveCount,
      total,
      textContent: `${total ? format0((productiveCount * 100) / total) : 0}%`,
      cutOffs: [0.8, 0.7],
      pending: {
        MTD: { label: `${total - productiveCount}  non-productive` },
        daily: { label: `${total - productiveCount} non-productive` },
      },
    },
    {
      label: 'P1M active stores',
      link: 'activeStores',
      permission: 'gtm.salesTeamDashboard.p1mActiveStores',
      value: tab === 'MTD' ? activeRetailersCount : activeBeatRetailersCount,
      total: tab === 'MTD' ? allBeatRetailersCount : beatRetailersCount,
      textContent:
        tab === 'MTD'
          ? `${format0((activeRetailersCount * 100) / allBeatRetailersCount)}%`
          : `${format0((activeBeatRetailersCount * 100) / beatRetailersCount)}%`,
      cutOffs: [0.9, 0.8],
      pending: {
        MTD: { label: `${allBeatRetailersCount - activeRetailersCount}  not active` },
        daily: { label: `${beatRetailersCount - activeBeatRetailersCount} not active` },
      },
    },
    {
      label: 'IGP',
      link: 'igp',
      permission: 'gtm.salesTeamDashboard.igp',
      value: igpAchieved,
      total: igpTarget,
      textContent: `${format0((igpAchieved * 100) / igpTarget)}%`,
      cutOffs: [1, 0.9],
      pending: {
        MTD: { label: `${igpTarget - igpAchieved} not achieved` },
        daily: { label: `${igpTarget - igpAchieved} not achieved` },
      },
    },
    {
      label: 'QDGP',
      link: 'dgp',
      permission: 'gtm.salesTeamDashboard.dgp',
      value: dgpAchieved,
      total: dgpTarget,
      textContent: `${format0((dgpAchieved * 100) / dgpTarget)}%`,
      cutOffs: [1, 1],
      colorCode:
        tab === 'MTD'
          ? dgpAchieved / dgpTarget > prevMonthDgpAchieved / prevMonthDgpTarget
            ? greenDarkPh
            : redDarkPh
          : 'none',
      pending: {
        MTD: { label: `${dgpTarget - dgpAchieved} not achieved` },
        daily: { label: `${dgpTarget - dgpAchieved} not achieved` },
      },
    },
    {
      label: 'Coverage',
      link: 'mtdActiveStores',
      permission: 'gtm.salesTeamDashboard.mtdActiveStores',
      value: tab === 'MTD' ? mtdActiveRetailersCount : '',
      total: tab === 'MTD' ? allBeatRetailersCount : '',
      textContent:
        tab === 'MTD'
          ? `${format0((mtdActiveRetailersCount * 100) / allBeatRetailersCount)}%`
          : `--`,
      cutOffs: [0.9, 0.8],
      pending: {
        MTD: { label: `${allBeatRetailersCount - mtdActiveRetailersCount}  not covered` },
        daily: { label: `--` },
      },
    },
    {
      label: 'Green Outlets',
      link: 'mtdGreenOutlets',
      permission: 'gtm.salesTeamDashboard.outletsTarget',
      value: tab === 'MTD' ? achievedOutlets : '',
      total: tab === 'MTD' ? totalOutlets : '',
      textContent: tab === 'MTD' ? `${format0((achievedOutlets * 100) / totalOutlets)}%` : '--',
      cutOffs: [0.8, 0.6],
      pending: {
        MTD: { label: `${totalOutlets - achievedOutlets}  not green outlets` },
        daily: { label: '--' },
      },
    },
    {
      label: 'Achieved MSL',
      link: 'mtdMSL',
      permission: 'gtm.salesTeamDashboard.brandsTarget',
      value: tab === 'MTD' ? achievedBrands : '',
      total: tab === 'MTD' ? totalBrands : '',
      textContent: tab === 'MTD' ? `${format0((achievedBrands * 100) / totalBrands)}%` : `--`,
      cutOffs: [0.8, 0.6],
      pending: {
        MTD: { label: `${totalBrands - achievedBrands}  not achieved MSL` },
        daily: { label: `--` },
      },
    },
  ];

  const salesDoughnut = {
    MTD: {
      value: monthlySalesTarget ? sales / monthlySalesTarget : '--',
      head: 'Month to Date',
      heading: 'Monthly sales target',
      target: monthlySalesTarget,
      color:
        sales / monthlySalesTarget >= 1
          ? '#10B981'
          : sales / monthlySalesTarget > 0.9
          ? '#F59E0B'
          : '#EF4444',
    },
    daily: {
      value: sales / (daburPermission ? dailySalesTarget : ptmAverage),
      head: 'Today',
      heading: daburPermission ? 'Daily Sales Target' : 'Past 3 months avg',
      target: daburPermission ? dailySalesTarget : ptmAverage,
      color:
        sales / (daburPermission ? dailySalesTarget : ptmAverage) >= 1
          ? '#10B981'
          : sales / (daburPermission ? dailySalesTarget : ptmAverage) > 0.9
          ? '#F59E0B'
          : '#EF4444',
    },
    label: 'Sales value',
  };

  const gamification = (link) => {
    if (link) navigate(`/sales/ranking/${link}?tab=${tab}`);
  };
  return (
    <>
      {status === 'loading' || RSPDataLoading === 'loading' ? (
        <LinearProgress color="secondary" />
      ) : null}
      <TopNav onChange={setTab} value={tab} />
      {data ? (
        <>
          <div class="flex flex-col justify-start bg-salesGray flex-1 pb-20">
            <div
              class="rounded-mismatch cursor-pointer shadow-sales flex justify-between items-center px-4 py-4 bg-white my-2 mx-4"
              onClick={() => navigate(`/sales/ranking/salesValue?tab=${tab}`)}
            >
              <div class="w-2/3">
                <p class="font-semibold">Sales Value</p>
                <hr class="mb-4 mt-2 w-2/3" />
                <div class="flex items-center">
                  <img class="w-8 mr-3" src={require('images/today.png')} />
                  <div>
                    <p class="text-xs text-grey_tertiary">{salesDoughnut[tab]?.head}</p>
                    <p class="text-grey_secondary font-semibold text-lg">{formatCurrency(sales)}</p>
                  </div>
                </div>
                <hr class="my-2 w-1/2 ml-10" />
                <div class="flex items-center">
                  <img class="w-8 mr-3" src={require('images/p1m.png')} />
                  <div>
                    <p class="text-xs text-grey_tertiary">{salesDoughnut[tab]?.heading}</p>
                    <p class="text-grey_secondary font-semibold text-lg">
                      {formatCurrency(Math.round(salesDoughnut[tab]?.target))}
                    </p>
                  </div>
                </div>
                <hr class="my-2 w-1/2 ml-10" />
                <div class="flex items-center">
                  <img class="w-8 mr-3" src={require('images/p1m.png')} />
                  <div>
                    <p class="text-xs text-grey_tertiary">Throughput / Visit</p>
                    <p class="text-grey_secondary font-semibold text-lg">
                      {formatCurrency(Math.round(sales / productiveCount))}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class={`rounded-full h-28 w-28 md:h-24 md:w-24 md:mr-2 flex items-center mr-0 lg:mr-4 justify-center`}
                style={{ backgroundColor: salesDoughnut[tab]?.color }}
              >
                <p class="text-white font-semibold text-lg">{`${
                  salesDoughnut[tab]?.target && Math.round(salesDoughnut[tab]?.value * 100)
                }%`}</p>
              </div>
            </div>
            <div class="grid grid-cols-2 md:grid md:grid-cols-2 lg:grid-cols-3 mx-2">
              {doughnuts
                .filter((d) => permissions?.includes(d.permission))
                .map((doughnut) => (
                  <div
                    key={doughnut.label}
                    onClick={() => gamification(doughnut.link)}
                    class={`${
                      doughnut.label === 'Coverage' ||
                      doughnut.link === 'mtdMSL' ||
                      doughnut.link === 'mtdGreenOutlets'
                        ? tab !== 'MTD'
                          ? 'hidden'
                          : 'flex'
                        : ''
                    } ${
                      doughnut.link ? 'cursor-pointer' : ''
                    } rounded-mismatch shadow-sales m-2 py-2 flex flex-col justify-between items-center bg-white`}
                  >
                    <p class="font-normal text-grey_secondary">{doughnut.label}</p>
                    <div class="h-1/2 my-2" key={doughnut.label}>
                      <DoughnutChart {...doughnut} />
                    </div>
                    <p class="text-2xl font-semibold">
                      {doughnut.value}
                      <span class="font-normal text-xl">/{doughnut.total}</span>
                    </p>
                    <p class="text-xs text-grey_tertiary">{doughnut?.pending?.[tab]?.label}</p>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : null}
      <BottomNavSales index={0} />
    </>
  );
}
